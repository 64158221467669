import React, { useState } from "react";

import { fetchResponse } from "../../services/util";
import { formatSingleDateAndTimeString } from "../../utilities/DateFormatUtility";

import ContentBody from "../../common/Content/ContentBody";

import "./EventRegistrationForm.css";

const EventRegistrationForm = ({eventDate}) => {
  //state
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [postFormSubmissionMessage, setPostFormSubmissionMessage] = useState('');
  //labels
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selfShare, setSelfShare] = useState("");

  //construction skills checkbox
  const [flooringCheckbox, setFlooringCheckbox] = useState(false);
  const [landscapingCheckbox, setLandscapingCheckbox] = useState(false);
  const [constructionCheckbox, setConstructionCheckbox] = useState(false);
  const [electricalCheckbox, setElectricalCheckbox] = useState(false);
  const [wallpaperRemovalCheckbox, setWallpaperRemovalCheckbox] = useState(false);
  const [otherCheckbox, setOtherCheckbox] = useState(false);

  //equipment checkboxes
  const [largeFansCheckbox, setLargeFansCheckbox] = useState(false);
  const [trashBagsCheckbox, setTrashBagsCheckbox] = useState(false);
  const [pressureWashreCheckbox, setPressureWasherCheckbox] = useState(false);
  const [lobersCheckbox, setLobersCheckbox] = useState(false);
  const [shovelsCheckbox, setShovelsCheckbox] = useState(false);
  const [rakesCheckbox, setRakesCheckbox] = useState(false);
  const [edgersCheckbox, setEdgersCheckbox] = useState(false);

  const registrationSubmitHandler = async (event) => {
    event.preventDefault();
    console.log("default prevented");
    //hide the form after submission
    setFormSubmitted(true);
    console.log(`first name: ${firstName}`);
    const skillsString = buildSkillsString();
    console.log(`skill string: ${skillsString}`);
    const toolsString = buildToolsString();
    console.log(`tools string: ${toolsString}`);

    //TODO: validate the data exists
    const userName = `${firstName} ${lastName}`;

    //TODO: add loading wheel
    try {
      //TODO: SWAP FOR CUSTOM API
        //TEST
      const registerResult = await fetchResponse(
        "error fetching sheet data",
        "https://api.hopehousefl.org/register/event",
        "POST",
        { "Content-Type": "application/json" },
        JSON.stringify({
          registration_event: "event_registration",
          first_name: firstName,
          last_name: lastName,
          event_date: eventDate,
          email: email,
          phone: phone,
          skills: skillsString,
          equipment: toolsString,
          additional_info: selfShare,
          submitted_on: formatSingleDateAndTimeString(new Date())
        })
      );

      if (!registerResult.success) {
        //in the event of failure
        setPostFormSubmissionMessage("We are sorry, an error has occured, please use one of the contact options at the bottom of the page to get in touch with us!");
      } else {
        setPostFormSubmissionMessage("Thank you for registering to serve with us!");
      }

      console.log(
        `event registration result: ${JSON.stringify(registerResult)}`
      );
    } catch {
      //in this case there is a larger failure with the form
      setPostFormSubmissionMessage("We are sorry, an error has occured, please use one of the contact options at the bottom of the page to get in touch with us!");
    } 
    // TODO: implement error handling
  };



  const buildSkillsString = () => {
    let skillsStringAarray = [];
    //admin
    if (flooringCheckbox) {
      skillsStringAarray.push("Flooring (carpet, tile, vinyl, etc)");
    }
    //landscaping
    if (landscapingCheckbox) {
      skillsStringAarray.push("landscaping");
    }
    //construction
    if (constructionCheckbox) {
      skillsStringAarray.push("construction");
    }
    //Curriculum/Programs
    if (electricalCheckbox) {
      skillsStringAarray.push("Electrical");
    }
    //Donor/Development
    if (wallpaperRemovalCheckbox) {
      skillsStringAarray.push("Wallpaper Removal");
    }
    //other
    if (otherCheckbox) {
      skillsStringAarray.push("other");
    }

    const skillsString = buildCSString(skillsStringAarray);
    return skillsString;
  };

  const buildToolsString = () => {
    let toolStringArray = [];
    // large fan
    if (largeFansCheckbox) {
      toolStringArray.push("Large Fans");
    }
    // trash bags
    if (trashBagsCheckbox) {
      toolStringArray.push("Contractor Trash Bags");
    }
    // pressure washer
    if (pressureWashreCheckbox) {
      toolStringArray.push("Pressure Washer");
    }
    // lobbers
    if (lobersCheckbox) {
      toolStringArray.push("Lobbers");
    }
    // shovels
    if (shovelsCheckbox) {
      toolStringArray.push("Shovels");
    }
    // rakes
    if (rakesCheckbox) {
      toolStringArray.push("Rakes");
    }
    //edgers
    if (edgersCheckbox) {
      toolStringArray.push("Edgers");
    }

    const toolsString = buildCSString(toolStringArray);
    return toolsString;
  }

  const buildCSString = (inputStringArray) => {
    let constructedString = "";

    //loop
    for (let index = 0; index < inputStringArray.length; index++) {
      const element = inputStringArray[index];
      if (index !== 0) {
        constructedString += ", ";
      }
      constructedString += element;
    }

    //return
    return constructedString;
  };

  return (
    <div className="event-form">
      <div className="event-form__container">
        <div className="event-form__container-form-wrapper">
          {!formSubmitted ? (
            <form>
              {/** form title */}
              {/* <FormTitle color={'white'} title={"Get Involved"} /> */}
              <ContentBody color={"white"} body={"Serve Day Sign up!"} />
              {/** first name */}
              <div className="event-form__item">
                <label htmlFor="fname">First name:</label>
                <input
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  type="text"
                  id="fname"
                  name="fname"
                />
              </div>
              {/** last name name */}
              <div className="event-form__item">
                <label htmlFor="lname">Last name:</label>
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
              {/** email */}
              <div className="event-form__item">
                <label htmlFor="email">Email:</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              {/** phone */}
              <div className="event-form__item">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={(event) => setPhone(event.target.value)}
                />
              </div>
              {/** Skills checkbox list */}
              <div className="event-form__item">
                <div className="event-form__checkbox-wrapper">
                  <label for="event-form__checkbox">
                    Do you have any specific constructionremodeling skills?
                    <br></br>
                    <br></br>
                    Select all that apply:
                  </label>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={flooringCheckbox}
                      onChange={(event) =>
                        setFlooringCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="flooring-checkbox"
                      name="flooring-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="flooring-checkbox"
                    >
                      Flooring (carpet, tile, vinyl, etc)
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={landscapingCheckbox}
                      onChange={(event) =>
                        setLandscapingCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="landscaping-checkbox"
                      name="landscaping-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="landscaping-checkbox"
                    >
                      Landscaping
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={constructionCheckbox}
                      onChange={(event) =>
                        setConstructionCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="construction-checkbox"
                      name="construction-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="construction-checkbox"
                    >
                      Construction
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={electricalCheckbox}
                      onChange={(event) =>
                        setElectricalCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="electrical-checkbox"
                      name="electrical-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="electrical-checkbox"
                    >
                      Electrical
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={wallpaperRemovalCheckbox}
                      onChange={(event) =>
                        setWallpaperRemovalCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="wallpaper-removal-checkbox"
                      name="wallpaper-removal-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="wallpaper-removal-checkbox"
                    >
                      Wallpaper Removal
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={otherCheckbox}
                      onChange={(event) =>
                        setOtherCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="other-checkbox"
                      name="other-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="other-checkbox"
                    >
                      Other -add in comments below-
                    </label>
                  </div>
                </div>
              </div>
              {/** item check box list  */}
              <div className="event-form__item">
                <div className="event-form__checkbox-wrapper">
                  <label for="event-form__checkbox">
                    Do you have any tools you would be willing to bringuse
                    during this Serve Day?
                    <br></br>
                    <br></br>
                    Select all that apply:
                  </label>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={largeFansCheckbox}
                      onChange={(event) =>
                        setLargeFansCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="fan-checkbox"
                      name="fan-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="fan-checkbox"
                    >
                      Large Fans
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={trashBagsCheckbox}
                      onChange={(event) =>
                        setTrashBagsCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="trash-bags-checkbox"
                      name="trash-bags-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="trash-bags-checkbox"
                    >
                      Contractor Trash Bags
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={pressureWashreCheckbox}
                      onChange={(event) =>
                        setPressureWasherCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="pressure-washer-checkbox"
                      name="pressure-washer-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="pressure-washer-checkbox"
                    >
                      Pressure Washer
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={lobersCheckbox}
                      onChange={(event) =>
                        setLobersCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="lobbers-checkbox"
                      name="lobbers-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="lobbers-checkbox"
                    >
                      Lobbers
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={shovelsCheckbox}
                      onChange={(event) =>
                        setShovelsCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="shovels-checkbox"
                      name="shovels-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="shovels-checkbox"
                    >
                      Shovels
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={rakesCheckbox}
                      onChange={(event) =>
                        setRakesCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="rakes-checkbox"
                      name="rakes-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="rakes-checkbox"
                    >
                      Rakes
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={edgersCheckbox}
                      onChange={(event) =>
                        setEdgersCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="edgers-checkbox"
                      name="edgers-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="edgers-checkbox"
                    >
                      Edgers
                    </label>
                  </div>
                  <div className="event-form__checkbox">
                    <input
                      className="event-form__checkbox-input"
                      checked={otherCheckbox}
                      onChange={(event) =>
                        setOtherCheckbox(event.target.checked)
                      }
                      type="checkbox"
                      id="other-checkbox"
                      name="other-checkbox"
                    />
                    <label
                      className="event-form__checkbox-label"
                      for="other-checkbox"
                    >
                      Other -add in comments below-
                    </label>
                  </div>
                </div>
              </div>
              {/** comments */}
              <div className="event-form__item event-form__item-textarea-container">
                <label for="self-share">
                  Is there anything else youd like to share with us?
                </label>
                <input
                  className="event-form__input-textarea"
                  type="textarea"
                  id="self-share"
                  name="self-share"
                  onChange={(event) => setSelfShare(event.target.value)}
                />
              </div>
              {/** TODO: add text field for anything else you'd like to share */}
              <div className="event-form__cta-container">
                <button
                  className="event-form__cta"
                  onClick={registrationSubmitHandler}
                >
                  Submit
                </button>
              </div>
            </form>
          ) : (
            <ContentBody
              color={"white"}
              body={postFormSubmissionMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventRegistrationForm;
