import React from "react";

import Banner from "../common/Banner/Banner";
import VolunteerForm from "../forms/VolunteerForm";
import TextBlock from "../common/TextBlocks/TextBlock";

import "./VolunteerView.css";
import HoverPhotoBlock from "../common/PhotoTextBlocks/HoverPhotoBlock";

import getInvolved1 from '../../assets/get-involved/get-involved-1.png';

import churchPartnerContent from '../../content/ChurchPartnerContent.json';

const VolunteerView = ({
  onBannerClick
}) => {
  return (
    <div className="volunteer-main">
      <Banner onBannerClick={onBannerClick} isVideoBanner={true} labelText={"GET INVOLVED"}/>
      {/** insert container with four hover blocks */}
      {/* <TextBlock
        header={"ways to get involved"}
        body={
          "insert four blocks | serve day | church partner | volunteer registration"
        }
      />
      <HoverPhotoBlock image={getInvolved1} text={churchPartnerContent} /> */}
      <VolunteerForm />
    </div>
  );
};

export default VolunteerView;
