/**
 * 
 * @param {Date} eventDateStartString 
 * @returns {String} formatted event date
 */

const formatEventDateString = (eventDateStartString) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const eventDateStartObj = new Date(eventDateStartString);

  const day = eventDateStartObj.getDate();
  const month = months[eventDateStartObj.getMonth()];
  const year = eventDateStartObj.getFullYear();

  const compiledDateString = `${month} ${day}, ${year}`;

  return compiledDateString;
};

/**
 * 
 * @param {Date} eventStartDateString 
 * @param {Date} eventEndDateString 
 * @returns formatted string time range for an event
 */
const formatEventTimeString = (eventStartDateString, eventEndDateString) => {
  const eventDateStartObj = new Date(eventStartDateString);
  const eventDateEndObj = new Date(eventEndDateString);

  const startHour = eventDateStartObj.getHours();
  const startMinutes = eventDateStartObj.getMinutes();
  const endHour = eventDateEndObj.getHours();
  const endMinutes = eventDateEndObj.getMinutes();

  const [startHoursString, startHoursAmPm] = formatHours(startHour);
  const startMinuteString = startMinutes.toString().padStart(2, "0");
  const [endHourString, endHoursAmPm] = formatHours(endHour);
  const endMinuteString = endMinutes.toString().padStart(2, "0");

  const formattedTimeString = `${startHoursString}:${startMinuteString} ${startHoursAmPm}- ${endHourString}:${endMinuteString} ${endHoursAmPm}`;

  return formattedTimeString;
};

/**
 * method to convert 24 hr clock time to 12 hr clock time and add am pm
 * @param {*} inputHours the 24 hour clock number
 * @returns returns a tuple where item 1 is an int 12 hour clock number and item 2 is the ampm [12 hour clock int, am pm string]
 */
const formatHours = (inputHours) => {
  var hour = inputHours;
  var ampm = hour >= 12 ? "pm" : "am";

  //format times for 12 hour clocks
  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour += 12;
  }

  return [hour, ampm];
}

/**
 * 
 * @param {Date} inputDateObject 
 * @returns formatted date object MM/dd/yyyy HH:ss
 */
const formatSingleDateAndTimeString = (inputDateObject) => {
  //format MM/dd/yyyy HH:ss
  
  const dateString = formatSingleDateString(inputDateObject);
  const hour = inputDateObject.getHours().toString().padStart(2, "0");
  const minutes = inputDateObject.getMinutes().toString().padStart(2, "0");

  const formattedString = `${dateString} ${hour}:${minutes}`;

  return formattedString;
}

/**
 * 
 * @param {Date} inputDateObject 
 * @returns formatted date object MM/dd/yyyy HH:ss
 */
const formatSingleDateString = (inputDateObject) =>{
  //format MM/dd/yyyy 

  const day = inputDateObject.getDate().toString().padStart(2, "0");
  // setting the month correctly (adding +1 to 0 based index)
  const month = (inputDateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDateObject.getFullYear();

  const formattedString = `${month}/${day}/${year}`;

  return formattedString;
}

/**
 * 
 * @param {Date} inputDateObject 
 * @returns formatted date string MM-dd-yy
 */
const formatSingleDateStringForUrl = (inputDateObject) =>{
  //format MM-dd-yy

  const day = inputDateObject.getDate().toString().padStart(2, "0");
  // setting the month correctly (adding +1 to 0 based index)
  const month = inputDateObject.getMonth() + 1;
  const year = inputDateObject.getFullYear();

  //take the last two digits of the url
  const formattedString = `${month}-${day}-${String(year).slice(-2)}`;

  return formattedString;
}

export {
  formatEventDateString, 
  formatEventTimeString, 
  formatSingleDateAndTimeString, 
  formatSingleDateString, 
  formatSingleDateStringForUrl
};