import React from 'react';
import { ColorSelection } from '../../utilities/ColorUtility';

import './ContentHeader.css';
import '../TextBlocks/Colors.css';

const ContentHeader = ({
    header,
    color
}) => {
    
    return (    
        <p className={('content-header ') + (color ? ColorSelection(color) : "hh-dark-blue")}>{header}</p>
    );
}

export default ContentHeader;