const ColorSelection = (
    inputColor
) => {
    switch (inputColor) {
        case "coral":
            return "hh-coral";
        case "dark-blue":
            return "hh-dark-blue";
        case "light-blue":
            return "hh-light-blue";
        case "yellow":
            return "hh-yellow";
        case "white":
            return "hh-white";
        case "black":
            return"hh-black";
        default:
            return "hh-coral";
    }
}

//TODO: combine these two utility functions
const BackgroundColorSelection = (
    inputColor
) => {
        switch (inputColor) {
          case "coral":
            return "hh-coral-background";
          case "dark-blue-background":
            return "hh-dark-blue-background";
          case "light-blue":
            return "hh-light-blue-background";
          case "yellow":
            return "hh-yellow-background";
          case "white":
            return "hh-white-background";
          default:
            return "hh-coral-background";
        }
}

export { ColorSelection, BackgroundColorSelection };
