import React from 'react';

import './ContentList.css';

const ContentList = ({
    contentListArray
}) => {

    return(
        <React.Fragment>
            <ul className='content-list'>
                {
                    contentListArray.map((listItem) => {
                        return(
                            <li className='content-list__item'>
                                {listItem}
                            </li>
                        );
                    })
                }
            </ul>
        </React.Fragment>
    );
}

export default ContentList;