// package
import React, { useEffect } from 'react';

// custom
import gala_invite from '../../../assets/gala/NOH_invite.png';
import gala_invite_mobile from '../../../assets/gala/NOH_invite-mobile.png';

//styling
import "./GalaPage.css";

const setForm = (w, d, s, id) => {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }
    js = d.createElement(s); 
    js.id = id;js.src = "https://secure.qgiv.com/resources/core/js/embed.js";
    fjs.parentNode.insertBefore(js, fjs);
}

const GalaPage = ({
    onBannerClick
}) => {

    useEffect(() => {
        setForm(window, document, "script", "qgiv-embedjs")
    }, []);

    return (
      <div className="gala-main">
        <div className="gala-main__images">
            <img src={gala_invite}/>
        </div>
        {/**TOOO: add mobile image */}
        <div className='gala-main__images-mobile'>
          <img src={gala_invite_mobile}/>
        </div>

        <div className="gala-main__qgiv-container">
          <button
            id="qgiv-load-modal-69170"
            class="qgiv-load-modal btn-styled-69170"
            data-event-url="https://secure.qgiv.com/for/hophou/event/873496/embed/69170/"
            data-embed-id="69170"
            data-event-name="Night of Hope Gala"
          >
            Get Tickets
          </button>
        </div>
      </div>
    );
}


export default GalaPage;