import React from 'react';

import './DropDown.css';

const DropDown = ({
    toggleDropDown,
    listItemArray,
    selectionHandler
}) => {
    
    const dropDownBlurHandler = () => {
        console.log("drop down blurred")
        toggleDropDown(false);
    }

    return (
        <div className='drop-down-menu' onBlur={dropDownBlurHandler}>
            <ul className='drop-down-menu__items'>
                {listItemArray.map((listItem) => {
                    return (
                        <li>
                            <a onClick={() => selectionHandler(listItem)}>{listItem}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default DropDown