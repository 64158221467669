import React from 'react';

import './DividerBar.css';

const DividerBar = () => {
    return(
        <hr className='divider-bar-default'/>
    )
}

export default DividerBar