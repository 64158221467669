import React from 'react';

import "./MissionView.css";

import Banner from '../common/Banner/Banner';
import HalfPhotoText from '../common/PhotoTextBlocks/HalfPhotoText';
import ContentList from '../common/Content/ContentList';
import HalfPhotoTextLeft from '../common/PhotoTextBlocks/HalfPhotoTextLeft';
import VideoFrame from '../common/Video/VideoFrame';

//content imports
import Mission from '../../content/Mission.json';
import Vision from '../../content/Vision.json';
import Values from '../../content/CoreValues.json';

import videoStill from '../../assets/hh-banner-house.jpg';
import about1 from '../../assets/about-us/about-us-1.jpg';
import about2 from '../../assets/about-us/about-us-2.jpg';
import about3 from '../../assets/about-us/about-us-3.jpg';


const MissionView = ({
  onBannerClick
}) => {

    //mission
    const missionHeader = Mission.header;
    const missionBody = Mission.body;
    //vision
    const visionHeader = Vision.header;
    const visionBody = Vision.body;
    //values
    const valuesHeader = Values.header;
    const valuesBody = Values.body;

    const splitHeader = (headerToSplit) => {
        const headerPieces = headerToSplit.split(" ");
        return headerPieces;
    }

    const valueArray = valuesBody.split(", ");

    //TODO: Implement fixed logo slightly transparent background on mission page 
    // background-image, background-attachment, background-position, background-size, background-repeat
    return (
      <div className="mission-content">
        <Banner onBannerClick={onBannerClick} labelText={"ABOUT US"}/>
        {/* HEADING TEXT */}
        <div className='mission-content__lead-paragraph-wrapper'>
          <div className="mission-content__lead-paragraph">
            <p className="mission-content__lead-paragraph-text">
              We believe that{" "}
              <span className="mission-content__lead-paragraph-text-highlight">
                every mom
              </span>{" "}
              and{" "}
              <span className="mission-content__lead-paragraph-text-highlight">
                every life
              </span>{" "}
              is worthy of our best and we are here to ensure that new moms are
              equipped to not only survive, but also to thrive in this bold,
              life-changing decision.
            </p>
          </div>
        </div>
        <VideoFrame image={videoStill}/>
        <HalfPhotoText
          image={about1}
          header={missionHeader}
          body={missionBody}
        />
        {/* VISION */}
        <HalfPhotoTextLeft
          image={about2}
          header={visionHeader}
          body={visionBody}
        />
        {/* VALUES */}
        <HalfPhotoText 
          image={about3} 
          header={valuesHeader} 
          body={<ContentList 
          contentListArray={valueArray}/>} 
        />
        {/* <DividerBar />
        <TextBlock header={"Rss Feed goes here"} body={"do rss feed stuff"} /> */}
      </div>
    );
}

export default MissionView