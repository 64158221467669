import React from "react";

import "./VideoFrame.css";

const VideoFrame = ({
  image
}) => {
  return (
    <div className="video-frame__wrapper">
      <video
        className="video-frame__content"
        controls
        poster={image}
        src="https://www.hopehousefl.org/media/hh-story.mp4"
        type="video/mp4"
      />
    </div>
  );
};

export default VideoFrame;
