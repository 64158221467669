import React from 'react';

import './Banner.css'
import logo from '../../../assets/hh-web-logo-white.png'
import Video from '../Video/Video';

const Banner = ({
    altImage,
    labelText,
    isVideoBanner,
    isCta,
    isHome,
    onBannerClick
}) => {

  const handleButtonClick = (input) => {
    onBannerClick(input);
  }

  const handleLogoClick = () => {
    onBannerClick('home');
  }

  const getBannerClass = () => {
    var classString =''
    if (altImage) {
      //is home banner
      classString += 'alt-banner';
    } else {
      classString += 'banner';
      if (isHome) {
        classString += ' banner__home';
      }
    }

    return classString;
  }

    return (
      <div className={getBannerClass()}>

        {/* TODO: move and enlarge image per the new design<img src={logo} onClick={handleLogoClick}/> */}
        {/** check before video display */}
        {isVideoBanner && <Video />}
        {/** check before displaying buttons */}
        <div className="banner__page-label">
          {labelText}
        </div>
        {isCta && (
          <div className="banner__cta-container-wrapper">
            <div className="banner__body-cta-container">
              {/** TODO: change to button */}
              <a className="banner__body--cta" onClick={() => {handleButtonClick('volunteer')}}>
                GET INVOLVED
              </a>
            </div>
            <div className="banner__body-cta-container">
              <a className="banner__body--cta" onClick={() => {handleButtonClick('giving')}}>
                GIVE NOW
              </a>
            </div>
          </div>
        )}
      </div>
    );
}

export default Banner;