import React from 'react'

import "./Footer.css";
import facebokIcon from '../../assets/facebook-4-512-round.png'
import emailIcon from '../../assets/email-14-512-round.png';
import instagramIcon from '../../assets/instagram-4-512-round.png'

const Footer = () => {

    //component logic

    return(
        <div className='footer-main'>
            <p>CONNECT WITH US</p>
            <div className='footer-main__contact-links-container'>
                <ul className='footer-main__items'>
                    <li className='footer-main__item'>
                        <a className='footer-main__contact-link' href='https://www.facebook.com/HisHopeHouseFL'>
                            <img src={facebokIcon}/>
                        </a>
                    </li>
                    <li className='footer-main__item'>
                        <a className='footer-main__contact-link' href='mailto: contact-us@hopehousefl.org'>
                            <img src={emailIcon}/>
                        </a>
                    </li>
                    <li className='footer-main__item'>
                        <a className='footer-main__contact-link' href='https://www.instagram.com/hope_house_fl/'>
                            <img src={instagramIcon}/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );

}

export default Footer;