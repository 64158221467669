import React from 'react';
import ContentBody from '../Content/ContentBody';
import ContentHeader from '../Content/ContentHeader';
import PhotoCube from './PhotoCube';

import './HalfPhotoText.css';

const HalfPhotoTextLeft = ({
    image,
    header,
    body,
    body2
}) => {
    //single photo with content element
    return(
        <div className='half-photo-text'>
            <div className='half-photo-text__right'>
                <div className='half-photo-text__right-content-wrapper'>
                    <ContentHeader header={header}/>
                    <ContentBody body={body}/>
                    {
                        body2 && <ContentBody body={body2}/>
                    }
                </div>
            </div>
            <div className='half-photo-text__left'>
                {/* photo cube here */}
                <PhotoCube style={'half'} image={<img src={image}/>}/>
            </div>
        </div>
    );
}

export default HalfPhotoTextLeft;