import React from 'react';
import ContentBody from '../Content/ContentBody';
import ContentHeader from '../Content/ContentHeader';
import PhotoCube from './PhotoCube';
import { BackgroundColorSelection } from '../../utilities/ColorUtility';

import './HalfPhotoText.css';
import '../TextBlocks/BackgroundColors.css'

const HalfPhotoText = ({
    image,
    header,
    body,
    textColor,
    backgroundColor,
    isVideo,
    videoAsset
}) => {
    //single photo with content element
    return (
        //set background color for photo first, defautl to white
      <div className={`half-photo-text ${backgroundColor ? BackgroundColorSelection(backgroundColor) : 'hh-white-background'}`}>
        <div className={`half-photo-text__left ${isVideo ? " video" : ""}`}>
          {/* photo cube here */}
          {/** if is video, image is poster and video attribte is present */}
          <PhotoCube style={"half"} image={ isVideo ? image : <img src={image}/>} isVideo={isVideo} videoAsset={videoAsset}/>
        </div>
        <div className="half-photo-text__right">
          <div className="half-photo-text__right-content-wrapper">
            <ContentHeader header={header} color={textColor} />
            <ContentBody body={body} color={textColor}/>
          </div>
        </div>
      </div>
    );
}

export default HalfPhotoText;