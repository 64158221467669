import React from "react";

import "./EventsPage.css";

import Banner from "../../common/Banner/Banner";
import ContentBody from "../../common/Content/ContentBody";
import ContentHeader from "../../common/Content/ContentHeader";
import DividerBar from "../../common/Divider/DividerBar";
import CtaButton from "../../common/Button/CtaButton";
import { formatEventDateString, formatEventTimeString, formatSingleDateStringForUrl } from "../../utilities/DateFormatUtility";
import { useNavigate } from "react-router-dom";

//serve day content
    //content is an array
import events from "../../../content/temp-content/events/events.json";

//TODO:
  //AUTO GENERATE SERVE DAYS EVERY SATURDAY UNTIL 9/9
  // NOTE THIS ONLY WORKS AS LONG AS EVERYTHING IS ALLOWED TO LOOK GENERIC

// a single event component consumed by the events view
const Event = ({ event, handleRegistrationButtonClick }) => {
  //extract content
  //time
  const startDate = new Date(event.eventStart);
  const endDate = new Date(event.eventEnd);
  const dateText = `Date: ${formatEventDateString(startDate, endDate)}`;
  const timeText = `Time: ${formatEventTimeString(startDate, endDate)}`;

  //location text
  const location = `Location: ${event.location}`;

  return (
    <div className="event-container">
      <ContentHeader color={"white"} header={event.title} />
      <ContentBody eventList={true} color={"white"} body={dateText} />
      <ContentBody eventList={true} color={"white"} body={timeText} />
      <ContentBody eventList={true} color={"white"} body={location} />
      <ContentBody eventList={true} color={"white"} body={event.content} />
      <div className="event-container__cta-wrapper">
        { event.registration_active ?
        <CtaButton
          label={"REGISTER"}
          info={formatSingleDateStringForUrl(startDate)}
          clickHandler={handleRegistrationButtonClick}
        /> :
        <div className="event-container__event-closed-text">
          <ContentBody eventList={true} color={"white"} body={"We are sorry, registration for this serve day is currently closed"}/>
        </div>
        }
      </div>
    </div>
  );
};

const getEventsList = (events, handleRegistrationButtonClick) => {
  var eventsList = [];

  events.forEach((eventItem) => {
    const eventEnd = new Date(eventItem.eventEnd);
    //determine if event should be displayes
    if (new Date() < eventEnd) {
      //return the event object
      eventsList.push(
        <React.Fragment>
          <Event
            event={eventItem}
            handleRegistrationButtonClick={handleRegistrationButtonClick}
          />
          <DividerBar />
        </React.Fragment>
      );
    }
  });

  if (eventsList.length > 0) {
    return <div events-main__events-list>{eventsList}</div>;
  } else {
    const emptyListMessage = "There are no events currently listed, please check back later!"
    return <ContentBody eventList={false} color={"white"} body={emptyListMessage} />
  }

};

const EventsPage = ({ onBannerClick }) => {

  let navigate = useNavigate();

  const handleRegistrationButtonClick = (event) => {
    event.preventDefault();
    //collect the event date to pass to the registration form
    let path = `/${event.target.getAttribute("info")}`;
    //navigate
    navigate(path);
  };

  const handleWavierButtonClic = (event) => {
    event.preventDefault();
    let path = '/waiver';
    navigate(path);
  }

  return (
    <div className="events-main">
      <Banner onBannerClick={onBannerClick} labelText={"SERVE DAYS"} />
      {/** popoulate events list */}
      {getEventsList(events, handleRegistrationButtonClick)}
      <div className="events__waiver-button-container">
        {/** waiver button */}
        <ContentHeader header="Waiver" />
        <ContentBody body="Already registered for a serve Day but need to sign a waiver? Click the button below!" />
        <button className="events-registration__button" onClick={handleWavierButtonClic}>
          Waiver
        </button>
      </div>
    </div>
  );
};

export default EventsPage;