import React from 'react';

import './Video.css';

const Video = () => {
  const videoLink = "https://www.hopehousefl.org/media/hh-serves-vid.mp4";
    return (
      <React.Fragment>
        <video
          className="video-content"
          autoPlay
          muted
          playsInline
          loop
          src={videoLink}
          type="video/mp4"
        />
      </React.Fragment>
    );
}

export default Video;