import React from 'react';

import './OneThirdPhotoText.css';
import ContentHeader from '../Content/ContentHeader';
import ContentBody from '../Content/ContentBody';

const OneThirdPhotoText = ({
    photoCubeArray,
    contentArray,
    textColor
}) => {
    //this dynamically renders the content passed into it so it can be as high as is required

    return(
        <div className='one-third-photo-text'>
            <div className='one-third-photo-text__left'>
                {photoCubeArray.map((photoCube) => {
                    return (
                        <React.Fragment>
                            {photoCube}
                        </React.Fragment>
                    );
                })}
            </div>
            <div className='one-third-photo-text__right'>
                <div className='one-third-photo-text__right-content-wrapper'>
                    <div className='one-third-photo-text__right-content'>
                        {
                            contentArray.map((contentItem) => {
                                return(
                                    <React.Fragment>
                                        {/** header and body color are the same temproarily */}
                                        <ContentHeader header={contentItem.header} color={textColor}/>
                                        <ContentBody body={contentItem.body} color={textColor}/>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OneThirdPhotoText