import React from 'react';
import DynamicVideo from '../../common/Video/DynamicVideo'

import './PhotoCube.css';

const PhotoCube = ({
    style,
    image,
    isVideo,
    videoAsset
}) => {
    const determinePhotoSize = (photoSizeString) => {
        switch(photoSizeString) {
            case "third":
                return "photo-cube__third"
            case "half":
                return "photo-cube__half"
            case "stackable":
                return "photo-cube__stackable"
            default:
                console.log("error selecting size, defaulting to half");
                return "photo-cube__half"
        }
    }
    //dynamically size photo cube
    return (
      <div className={`photo-cube ${determinePhotoSize(style.toLowerCase())} ${isVideo ? 'video-cube' : ""}`}>
        {/** if is video, play video */}
        {isVideo ? (
          <DynamicVideo
            videoAsset={videoAsset}
            poster={image}
          />
        ) : (
          image
        )}
      </div>
    );
}

export default PhotoCube;