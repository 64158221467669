import React, { useEffect } from 'react';

//CSS
import './WaiverView.css';
import Banner from "../common/Banner/Banner";

const FormContainer = () => {
  // We use the useEffect hook to execute the code once the component mounts.
  useEffect(() => {
    // The config object contains the configuration for the form.
    const config = {
      nodeId: "form-container-a7d8ea95-bcb2-4eaa-a5f5-2764941794b8", // The ID of the div where the form will be rendered.
      width: "100%", // The width of the form.
      height: "700px", // The height of the form.
      url: "https://eform.pandadoc.com/?eform=1c970e48-dda8-4992-b7ed-425955505885", // The URL of the form.
      events: {
        // The events that can be triggered by the form.
        loaded: function () {}, // Triggered when the form has finished loading.
        started: function (data) {}, // Triggered when the form has started.
        completed: function (data) {}, // Triggered when the form has been completed.
        exception: function (data) {}, // Triggered when an exception has occurred.
      },
      data: {}, // Any additional data that needs to be sent to the form.
    };

    // If there is additional data, we append it to the URL as a query string.
    const dataQueryString = config.data
      ? Object.keys(config.data)
          .map(function (key) {
            return (
              "&" +
              key +
              "=" +
              encodeURIComponent(JSON.stringify(config.data[key]))
            );
          })
          .join("")
      : "";

    // We create an iframe element and set its attributes.
    const iframe = document.createElement("iframe");
    iframe.frameBorder = 0;
    iframe.src = config.url + dataQueryString;

    // If there is a node ID specified in the config, we append the iframe to that node.
    if (config.nodeId) {
      const node = document.getElementById(config.nodeId);
      node.style.height = config.height;
      node.style.width = config.width;
      iframe.height = "100%";
      iframe.width = "100%";
      node.append(iframe);
    } else {
      // Otherwise, we append the iframe to the body of the page.
      iframe.height = config.height;
      iframe.width = config.width;
      document.body.append(iframe);
    }

    // We add an event listener to listen for messages sent by the form.
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";

    window[eventMethod](
      messageEvent,
      function (e) {
        if (
          e &&
          e.data &&
          config &&
          iframe &&
          e.source === iframe.contentWindow
        ) {
          try {
            // We parse the message data as JSON and extract the event and data properties.
            const message = JSON.parse(e.data);
            if (message && message.event) {
              // We extract the event name and call the corresponding callback function.
              const event = message.event.replace("embed.form.", "");
              const callback = config.events ? config.events[event] : null;
              if (callback) {
                callback(message.data);
              }
            }
          } catch (e) {}
        }
      },
      false
    );

    // We return a cleanup function that removes the iframe from the DOM when the component unmounts.
    return () => {
      const node = document.getElementById(config.nodeId);
      node.innerHTML = "";
    };
  }, []); // We pass an empty array
  
  return <div id="form-container-a7d8ea95-bcb2-4eaa-a5f5-2764941794b8"></div>;

};

//Waiver Page
const WaiverView = ({
  //props
  onBannerClick
}) => {
  return <div className='waiver-view'>
    <Banner onBannerClick={onBannerClick} isVideoBanner={false} labelText={"BEFORE YOU SHOW UP"}/>
    <FormContainer/>
  </div>
};

export default WaiverView;