import React, {useEffect} from 'react';

import './GivingForm.css';

// basic form code extracted from qgive
const setForm = (w, d, s, id) => {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s); 
  js.id = id;
  js.src = "https://secure.qgiv.com/resources/core/js/embed.js";
  fjs.parentNode.insertBefore(js, fjs);
}


const GivingForm = () => {

  useEffect(() => {
    setForm(window, document, "script", "qgiv-embedjs");
  }, []);

    return (
      <div className="giving-form">
        <div className="qgiv-embed-container" data-qgiv-embed="true" data-embed-id="65760" data-embed="https://secure.qgiv.com/for/hophou/embed/65760/" data-width="400"></div>
      </div>
    );
}

export default GivingForm;