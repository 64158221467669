import React from "react";

import "./DynamicVideo.css";

const DynamicVideo = ({
    videoAsset,
    poster
}) => {
  return (
    <div className="dynamic-video-frame__wrapper">
      <video
        className="dynamic-video__content"
        controls
        poster={poster}
        src={videoAsset}
        type="video/mp4"
      />
    </div>
  );
};

export default DynamicVideo;
