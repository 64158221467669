import React from 'react';

import Banner from '../common/Banner/Banner';
import GivingForm from '../forms/GivingForm';
import ContentBody from '../common/Content/ContentBody';

import './GivingView.css';
import givingContent from '../../content/DonationText.json';


const GivingView = ({
  onBannerClick
}) => {
    return (
      <div className="giving-main">
        <Banner onBannerClick={onBannerClick} labelText={"FINANCIAL PARTNERSHIP"} />
        {/* <TextBlock header={"header text"} body={"body text"}/> */}
        <div className="giving-main__content">
          <div className='giving-main__content-body-wrapper'>
            <div className='giving-main__content-body'>
              <ContentBody body={givingContent.body} />
            </div>
          </div>
          {/** TODO: reinstate when document is updated */}
          {/* <div className="giving-main__body-cta-container">
            <a
              className="giving-main__body--cta"
              href="/documents/Hope-House_Sponsorship_Opportunities.pdf"
              download
            >
              SPONSORSHIP OPPORTUNITIES
            </a>
          </div> */}
        </div>
        {/** giving form */}
        {/** Sponsorship download */}
        <GivingForm />
      </div>
    );
}

export default GivingView;