import React, {useState} from 'react';

import { fetchResponse } from '../services/util';

import ContentBody from '.././common/Content/ContentBody';

import './VolunteerForm.css';

import VolunteerFormParagraph from '../../content/VolunteerFormParagraph.json';
import { formatSingleDateAndTimeString } from '../utilities/DateFormatUtility';

const VolunteerForm = () => {

    //state
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [postFormSubmissionMessage, setPostFormSubmissionMessage] = useState('');
  const [loading, setLoading] = useState(false);
  //labels
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selfDescription, setSelfDescription] = useState('');
  const [selfShare, setSelfShare] = useState('');

  //area of interest check box
  const [administrativeCheckbox, setAdministrativeCheckBox] = useState(false);
  const [landscapingCheckbox, setLandscapingCheckbox] = useState(false);
  const [constructionCheckbox, setConstructionCheckbox] = useState(false);
  const [programmingCheckbox, setProgrammingCheckbox] = useState(false);
  const [donorDevelopmentCheckbox, setDonorDevelopmentCheckbox] = useState(false);
  const [otherCheckbox, setOtherCheckbox] = useState(false);
   
  const volunteerSubmitHandler = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log("default prevented");
      //hide the form after submission
    console.log(`first name: ${firstName}`);
    const skillsString = buildSkillsString();
    console.log(`skill string: ${skillsString}`);

    const registerResult = await fetchResponse(
      "error fetching sheet data",
      "https://api.hopehousefl.org/register",
      "POST",
      { "Content-Type": "application/json" },
      JSON.stringify({
        registration_event: "volunteer_registration",
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        self_description: selfDescription,
        self_share: selfShare,
        skills: skillsString,
        submitted_on: formatSingleDateAndTimeString(new Date()),
      })
    );
    if (!registerResult.success) {
      //in the event of failure
      setPostFormSubmissionMessage("We are sorry, an error has occured, please use one of the contact options at the bottom of the page to get in touch with us!");
    } else {
      setPostFormSubmissionMessage("Thank you for volunteering to serve with us!");
    }

    setLoading(false);
    setFormSubmitted(true);

    console.log(`volunteer registration result: ${JSON.stringify(registerResult)}`)
    // TODO: implement error handling
  }

  const buildSkillsString = () => {
    let skillsStringAarray = [];
    //admin
    if (administrativeCheckbox) {
      skillsStringAarray.push("administrative");
    }
    //landscaping
    if (landscapingCheckbox) {
      skillsStringAarray.push("landscaping");
    }
    //construction
    if (constructionCheckbox) {
      skillsStringAarray.push("construction");
    }
    //Curriculum/Programs
    if (programmingCheckbox) {
      skillsStringAarray.push("Curriculum/Programs");
    }
    //Donor/Development
    if (donorDevelopmentCheckbox) {
      skillsStringAarray.push("Donor/Development")
    }
    //other
    if (otherCheckbox) {
      skillsStringAarray.push("other")
    }

    const skillsString = buildCSString(skillsStringAarray);
    return skillsString;
  }

  const buildCSString = (inputStringArray) => {
    let constructedString = "";

    //loop
    for (let index = 0; index < inputStringArray.length; index++) {
      const element = inputStringArray[index];
      if (index !== 0) {
        constructedString += ', ';
      }
      constructedString += element;
    }

    //return
    return constructedString;
  }

    return (
      <div className="volunteer-form">
        <div className="volunteer-form__container">
          <div className="volunteer-form__container-form-wrapper">
            {/** show  loader if required */}
            {loading && (
              <div className="volunteer-form__loader">
                <ContentBody color={"white"} body={"Loading . . ."} />
              </div>
            )}
            {!formSubmitted && !loading ? (
              <form>
                {/** form title */}
                {/* <FormTitle color={'white'} title={"Get Involved"} /> */}
                <ContentBody
                  color={"white"}
                  body={VolunteerFormParagraph.body}
                />
                {/** first name */}
                <div className="volunteer-form__name-container">
                  <div className="volunteer-form__item">
                    <label htmlFor="fname">First name:</label>
                    <input
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      type="text"
                      id="fname"
                      name="fname"
                    />
                  </div>
                  {/** last name name */}
                  <div className="volunteer-form__item">
                    <label htmlFor="lname">Last name:</label>
                    <input
                      type="text"
                      id="lname"
                      name="lname"
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </div>
                </div>
                {/** email */}
                <div className="volunteer-form__item">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                {/** phone */}
                <div className="volunteer-form__item">
                  <label htmlFor="phone">Phone:</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
                <div className="volunteer-form__item">
                  <div className="volunteer-form__checkbox-wrapper">
                    <div className="volunteer-form__checkbox">
                      <input
                        className="volunteer-form__checkbox-input"
                        checked={administrativeCheckbox}
                        onChange={(event) =>
                          setAdministrativeCheckBox(event.target.checked)
                        }
                        type="checkbox"
                        id="administrative-checkbox"
                        name="administrative-checkbox"
                      />
                      <label
                        className="volunteer-form__checkbox-label"
                        for="administrative-checkbox"
                      >
                        Administrative
                      </label>
                    </div>
                    <div className="volunteer-form__checkbox">
                      <input
                        className="volunteer-form__checkbox-input"
                        checked={landscapingCheckbox}
                        onChange={(event) =>
                          setLandscapingCheckbox(event.target.checked)
                        }
                        type="checkbox"
                        id="landscaping-checkbox"
                        name="landscaping-checkbox"
                      />
                      <label
                        className="volunteer-form__checkbox-label"
                        for="landscaping-checkbox"
                      >
                        Landscaping
                      </label>
                    </div>
                    <div className="volunteer-form__checkbox">
                      <input
                        className="volunteer-form__checkbox-input"
                        checked={constructionCheckbox}
                        onChange={(event) =>
                          setConstructionCheckbox(event.target.checked)
                        }
                        type="checkbox"
                        id="construction-checkbox"
                        name="construction-checkbox"
                      />
                      <label
                        className="volunteer-form__checkbox-label"
                        for="construction-checkbox"
                      >
                        Construction
                      </label>
                    </div>
                    <div className="volunteer-form__checkbox">
                      <input
                        className="volunteer-form__checkbox-input"
                        checked={programmingCheckbox}
                        onChange={(event) =>
                          setProgrammingCheckbox(event.target.checked)
                        }
                        type="checkbox"
                        id="programming-checkbox"
                        name="programming-checkbox"
                      />
                      <label
                        className="volunteer-form__checkbox-label"
                        for="programming-checkbox"
                      >
                        Curriculum/Programs
                      </label>
                    </div>
                    <div className="volunteer-form__checkbox">
                      <input
                        className="volunteer-form__checkbox-input"
                        checked={donorDevelopmentCheckbox}
                        onChange={(event) =>
                          setDonorDevelopmentCheckbox(event.target.checked)
                        }
                        type="checkbox"
                        id="donor-development-checkbox"
                        name="donor-development-checkbox"
                      />
                      <label
                        className="volunteer-form__checkbox-label"
                        for="donor-development-checkbox"
                      >
                        Donor/Development
                      </label>
                    </div>
                    <div className="volunteer-form__checkbox">
                      <input
                        className="volunteer-form__checkbox-input"
                        checked={otherCheckbox}
                        onChange={(event) =>
                          setOtherCheckbox(event.target.checked)
                        }
                        type="checkbox"
                        id="other-checkbox"
                        name="other-checkbox"
                      />
                      <label
                        className="volunteer-form__checkbox-label"
                        for="other-checkbox"
                      >
                        Other -add in questions below-
                      </label>
                    </div>
                  </div>
                </div>
                {/** comments */}
                <div className="volunteer-form__item volunteer-form__item-textarea-container">
                  <label for="self-description">
                    How do you see yourself being involved?
                  </label>
                  <input
                    className="volunteer-form__input-textarea"
                    type="textarea"
                    id="self-description"
                    name="self-description"
                    onChange={(event) => setSelfDescription(event.target.value)}
                  />
                  <label for="self-share">
                    Anything else you'd like to share?
                  </label>
                  <input
                    className="volunteer-form__input-textarea"
                    type="textarea"
                    id="self-share"
                    name="self-share"
                    onChange={(event) => setSelfShare(event.target.value)}
                  />
                </div>
                {/** TODO: add text field for anything else you'd like to share */}
                <div className="volunteer-form__cta-container">
                  <button
                    className="volunteer-form__cta"
                    onClick={volunteerSubmitHandler}
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <ContentBody color={"white"} body={postFormSubmissionMessage} />
            )}
          </div>
        </div>
      </div>
    );
}

export default VolunteerForm;