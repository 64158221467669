import React from 'react';
import { ColorSelection } from '../../utilities/ColorUtility';

import './ContentBody.css';
import '../TextBlocks/Colors.css';

const ContentBody = ({
    body,
    color,
    event,
    eventList
}) => {
    const buildClassName = () => {
        var className = "";
        className += event ? 'event-body ' : 'content-body ';
        if (eventList) {
            className += "event-list-body ";
        }
        className += color ? ColorSelection(color) : "hh-gray";

        return className;
    }

    return (
        <p className={buildClassName()}>
            {body}
        </p>
    );
}

export default ContentBody;