const fetchResponse = async (errorMessage, url, type, headers, body) => {
    const requestObject = {
        method: type || "get",
    };

    // set headers
    let requestHeaders = {};
    if (headers) {
        requestHeaders = {
            ...requestHeaders,
            ...headers,
        };
    }
    requestObject.headers = requestHeaders;

    //set body
    if (body) {
        requestObject.body = body;
    }

    //try the request
    try {
      const response = await fetch(url, requestObject);

      //parse response
      const responseBody = await response.text();

      let responseData = responseBody ? JSON.parse(responseBody) : {};

      if (response.ok) {
        return {
          success: true,
          data: responseData,
        };
      }

      return {
        success: false,
        status: response.status,
        errorMessage:
          errorMessage ||
          (typeof responseData === "object" && responseData?.message) ||
          "There was an error, please try again.",
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: error.toString(),
        cancelled: error.name === "AbortError",
      };
    }

};

export { fetchResponse };