import React from "react";
import { useNavigate } from "react-router-dom";
import EventRegistrationForm from "../Events/EventRegistrationForm";
import Banner from "../../common/Banner/Banner";
import ContentBody from "../../common/Content/ContentBody";
import ContentHeader from "../../common/Content/ContentHeader";
import { formatSingleDateString } from "../../utilities/DateFormatUtility";

//images
import parkingMap from "../../../assets/parking-map.jpeg";

//css
import "./EventsRegistrationPage.css";

const EventsRegistrationPage = ({
    eventDate, 
    registrationActive,
    servePartner
}) => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = "/waiver";
    navigate(path);
  };

  return (
    <div className="events-registration--main">
      {/** Banner */}
      <Banner
        labelText={servePartner ? `${servePartner.toUpperCase()}` : "Serve Day"}
      />
      {/** welcome message */}
      <div className="events-registration__welcome-message">
        {servePartner ? (
          <ContentBody
            body={`Welcome to our ${servePartner} Serve Day ${formatSingleDateString(
              new Date(eventDate)
            )}`}
          />
        ) : (
          <ContentBody
            body={`Welcome to our ${formatSingleDateString(
              new Date(eventDate)
            )} Serve Day`}
          />
        )}
      </div>
      {/** Registration Form */}
      <div className="events-registration__registration-header">
        <ContentHeader header="Registration" />
      </div>
      {/** conditionally render registration form */}
      { registrationActive ? 
        <EventRegistrationForm eventDate={eventDate} /> :
        <div className="events-registration__event-closed-text">
          <ContentBody body={"We are sorry, registration for this serve day is currently closed"}/>
        </div>
      }
      <div className="events-registration__button-container">
        {/** waiver button */}
        <ContentHeader header="Waiver" />
        <ContentBody body="Please fill out the serve day registration form and submit your waiver" />
        <button className="events-registration__button" onClick={routeChange}>
          Waiver
        </button>
      </div>
      {/** address */}
      <div className="events-registration__location">
        <ContentHeader header="Location" />
        <address>
          555 E Stanford St,
          <br />
          Bartow, FL 33830
        </address>
      </div>
      {/** Parking Map */}
      <div className="events-registration__parking-map-container">
        <ContentHeader header="Parking Map" />
        <img src={parkingMap} />
      </div>
    </div>
  );
};

export default EventsRegistrationPage;
