import React from 'react';

import './CtaButton.css';

const CtaButton = ({
    label,
    clickHandler,
    info
}) => {
    return (
      // added optional info tab that contains needed information at the time of click
      <button className="cta-button" onClick={clickHandler} info={info}>
        {label}
      </button>
    );
}

export default CtaButton;